import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Reference,
  TypePolicies,
  from,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import Auth from '@aws-amplify/auth'
import { datadogRum } from '@datadog/browser-rum'
import { getTokenAsync } from '@gameonsports/aws-amplify-hooks/cjs/getTokenAsync'
import theme from '@gameonsports/components/cjs/theme'
import { LocationProvider, navigate } from '@reach/router'
import { createClient } from 'graphql-ws'
import merge from 'lodash/merge'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from 'styled-components'
import { authConfig } from '../../constants/authConfig'
import { publicEnv } from '../../constants/publicEnv'
import { APOLLO_STATE_SCRIPT_ID } from '../../constants/scriptIds'
import { SSR_FORCE_FETCH_DELAY } from '../../constants/ssr'
import fragmentMatcher from '../../generated/graphql'
import typePolicies from '../../generated/graphql.typePolicies.json'
import { LogLevel, pushLog } from '../../utils/faro'
import { getTenantServiceName } from '../../utils/tenant'
import { ErrorBoundary } from '../ErrorBoundary'
import ErrorPage from '../ErrorPage'

Auth.configure(authConfig)

const typePoliciesOverride: TypePolicies = {
  Query: {
    fields: {
      gameEvents: {
        // Don't cache separate results based on any of this field's arguments.
        keyArgs: ['gameID'],

        merge(existing: Reference[] = [], incoming: Reference[]) {
          /**
           * Concatenate the incoming list items with the existing list items.
           * https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
           */
          const existingRefs = new Set(existing.map(d => d.__ref))
          const newEvents = incoming.filter(d => !existingRefs.has(d.__ref))

          return [...new Set([existing, newEvents].flat())]
        },
      },
    },
  },
  /**
   * GameEvent types have a client side field that needs to be resolved
   * Changes from local-spectator-schema.graphl should be reflected here
   */
  ScoreEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  FoulEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  DismissalEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  ExtraEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  MiscellaneousEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  PeriodSummaryEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
  PositionEvent: {
    fields: { isFromSubscription: { read: (value: any) => value ?? false } },
  },
}

const getApolloClient = () => {
  const apolloStateEl = document.getElementById(APOLLO_STATE_SCRIPT_ID)
  const apolloState = apolloStateEl?.innerHTML
    ? JSON.parse(apolloStateEl?.innerHTML)
    : {}
  ;(window as any).__APOLLO_STATE__ = apolloState

  const cache = new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies: merge(typePolicies, typePoliciesOverride),
  }).restore(apolloState)

  const reboundHttpLink = new HttpLink({
    uri: publicEnv.REACT_APP_GRAPH_ENDPOINT,
  })

  const searchHttpLink = new HttpLink({
    uri: publicEnv.REACT_APP_SEARCH_ENDPOINT,
  })

  const spectatorHttpLink = new HttpLink({
    uri: String(publicEnv.REACT_APP_SPECTATOR_ENDPOINT),
  })

  let wsTenant = ''

  const spectatorWsClient = publicEnv.REACT_APP_SPECTATOR_WS_ENDPOINT
    ? new GraphQLWsLink(
        createClient({
          url: () =>
            `${publicEnv.REACT_APP_SPECTATOR_WS_ENDPOINT}?tenant=${wsTenant}`,
          lazy: true,
          shouldRetry: () => true,
        }),
      )
    : undefined

  const spectatorSplitLink =
    spectatorHttpLink && spectatorWsClient
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          spectatorWsClient,
          spectatorHttpLink,
        )
      : undefined

  const searchSplit = split(
    operation => operation.getContext().endpoint === 'scout',
    searchHttpLink,
    reboundHttpLink,
  )

  const splitLink = spectatorSplitLink
    ? split(
        operation => operation.getContext().endpoint === 'spectator',
        spectatorSplitLink,
        searchSplit,
      )
    : searchSplit

  const authLink = setContext(
    async (_, { headers, tenant: tenantContext, endpoint }) => {
      if (tenantContext && spectatorWsClient && wsTenant !== tenantContext) {
        wsTenant = getTenantServiceName(tenantContext)
        spectatorWsClient.client.terminate()
      }

      // Do not pass the authorization and tenant headers to spectator or scout
      if (endpoint === 'spectator' || endpoint === 'scout') {
        return {
          headers,
        }
      }

      // Matching /:tenant/*.
      // Yes it will match /account as well, but that should not matter at all
      const match = window.location.pathname.match(/^\/([a-zA-Z-]+)+/)
      const tenant =
        headers && 'tenant' in headers ? headers.tenant : match && match[1]
      const serviceName = getTenantServiceName(tenant)

      try {
        const token = await getTokenAsync()
        if (!token)
          return {
            headers: {
              ...headers,
              tenant: serviceName,
            },
          }

        return {
          headers: {
            ...headers,
            authorization: token && `Bearer ${token}`,
            tenant: serviceName,
          },
        }
      } catch {
        return {
          headers: {
            ...headers,
            tenant: serviceName,
          },
        }
      }
    },
  )

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (process.env.REACT_APP_GRAFANA_FARO_URL) {
        pushLog(['Apollo network error'], {
          level: LogLevel.ERROR,
          context: {
            errorName: networkError.name,
            errorMessage: networkError.message,
            ...(networkError.stack && { errorStack: networkError.stack }),
          },
        })
      } else {
        // DataDog is still used in production, so keeping it here will allow
        // faro to cutover when the env variable is set.
        datadogRum.addError(networkError)
      }
      networkError.message = 'There was a problem. Please try again.'
    }

    if (graphQLErrors) {
      const unauthError = graphQLErrors.find(
        error =>
          error.message === 'Unauthorised' ||
          error.extensions?.code === 'UNAUTHORISED',
      )

      if (unauthError) {
        navigate('', {
          state: {
            unauthorised: true,
          },
        })
      }
    }
  })

  const link = from([authLink, errorLink, splitLink])

  const client = new ApolloClient({
    cache,
    link,
    ssrForceFetchDelay:
      (process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_MOCKS === 'true') ||
      // Ref: https://github.com/apollographql/apollo-client/issues/5918
      process.env.NODE_ENV === 'test'
        ? undefined
        : SSR_FORCE_FETCH_DELAY,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy(lastFetchPolicy) {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return 'cache-first'
          }
          return lastFetchPolicy
        },
      },
    },
  })

  return client
}

const AppProviders: React.FC = ({ children }) => {
  const client = getApolloClient()

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <LocationProvider>{children}</LocationProvider>
          </HelmetProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default AppProviders
